//import { differenceInMinutes } from "date-fns";
import ceil from "lodash/ceil";

// Function to calculate the UNIX timestamp for a specific time of day
export const convertToUnitTimestamp = (
  day: number,
  hour: number,
  minute: number
) => {
  const now = new Date();
  const expirationDate = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + day,
    hour,
    minute
  );

  // expirationDate.setHours(expirationDate.getHours() - 8);
  return Math.floor(expirationDate.getTime() / 1000);
};

// Set the expiration time for the cached data to 9 am

export const getDateDifference = (date: string | number | Date) => {
  //let diff = differenceInMinutes(new Date(), new Date(date));
  let diff = 59;
  if (diff < 60) return diff + " minutes ago";

  diff = ceil(diff / 60);
  if (diff < 24) return `${diff} hour${diff === 0 ? "" : "s"} ago`;

  diff = ceil(diff / 24);
  if (diff < 30) return `${diff} day${diff === 0 ? "" : "s"} ago`;

  diff = ceil(diff / 30);
  if (diff < 12) return `${diff} month${diff === 0 ? "" : "s"} ago`;

  diff = diff / 12;
  return `${diff.toFixed(1)} year${ceil(diff) === 0 ? "" : "s"} ago`;
};

export const sortBy = (key) => {
  return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
};
export function formatPercentage(amount: number) {
  return Intl.NumberFormat("en-GB", {
    style: "percent",
    minimumFractionDigits: 1,
    maximumFractionDigits: 2,
  }).format(amount);
}

export function formatCurrency(amount: number) {
  return (
    new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    }).format(amount) + "₮"
  );
}

export function formatNumber(amount: number) {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
  }).format(amount);
}

export function formatDate(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function setSearchParam(
  queryString: URLSearchParams,
  param: string,
  value: any
) {
  if (queryString.toString()) {
    return `?${queryString.toString()}&${param}=${value}`;
  }
  return `?${param}=${value}`;
}

export function sanitizeHtml(input: string): string {
  return input.replace(/<\/?[^>]+(>|$)/g, "");
}

export function graphqlHeader(session: any) {
  let header = {};
  return header;
}

export function calculateTimeDifferenceInSeconds(
  timestamp1: number,
  timestamp2: number
): number {
  const timeDifferenceInMilliseconds = timestamp2 - timestamp1;
  const timeDifferenceInSeconds = timeDifferenceInMilliseconds / 1000;
  return timeDifferenceInSeconds;
}

export function countdown() {
  return "1 udriin dotor";
}

export function replaceLastFourDigits(input: string): string {
  if (input.length >= 4) {
    const prefix = input.substring(0, input.length - 2);
    const asterisks = "*".repeat(2);
    return prefix + asterisks;
  } else {
    // Handle the case where the input string is too short
    return input;
  }
}
